import React, { useEffect } from 'react';
import { FormContainer } from './styledComponents';
import { FormRecord } from '~/config/generated/graphql';
import styled from 'styled-components';
import { Subtitle, CheckCircleIcon } from '@kvdbil/components';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { scroller } from 'react-scroll';
import { useSelector } from 'react-redux';
import { headerHeightSelector } from '~/App/shared/selectors/header';

const Title = styled(Subtitle)`
  text-align: center;
`;

const IconAndDescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-size: 4rem;
`;

const StructuredTextWrapper = styled.div`
  p {
    margin-top: 0.5rem;
  }
`;

type Props = {
  title: FormRecord['formTitle'];
  description: FormRecord['formSuccessSubmissionDescription'];
};

const SuccessInfo = ({ title, description }: Props) => {
  const headerHeight = useSelector(headerHeightSelector) ?? 0;

  useEffect(
    () =>
      scroller.scrollTo('success-info', {
        duration: 750,
        delay: 100,
        smooth: true,
        isDynamic: true,
        offset: (headerHeight + 16) * -1
      }),
    [headerHeight]
  );

  return (
    <FormContainer id="success-info">
      <Title as="h3">{title}</Title>
      <IconAndDescriptionWrapper>
        <StyledCheckCircleIcon />
        <StructuredText data={description} wrapper={StructuredTextWrapper} />
      </IconAndDescriptionWrapper>
    </FormContainer>
  );
};

export default SuccessInfo;
