import React from 'react';
import VehicleCardListContainer from '~/App/shared/components/VehicleCardList/components/VehicleCardListContainer';
import VehicleCardListItem, {
  WithExtraElementShownOnScreenSize
} from '~/App/shared/components/VehicleCardList/components/VehicleCardListItem';
import AuctionLoadingCard from '~/App/shared/components/AuctionLoadingCard';

interface VehicleLoadingCardsProps {
  numberOfCards?: number;
  isFilterList?: boolean;
}

const VehicleLoadingCards = ({
  numberOfCards = 4,
  isFilterList = false
}: VehicleLoadingCardsProps) => {
  return (
    <VehicleCardListContainer>
      {Array.from({ length: numberOfCards }, (_, index) => (
        <VehicleCardListItem
          key={`product-card-loader-${index}`}
          {...(isFilterList && {
            ...WithExtraElementShownOnScreenSize,
            laptopL: 4
          })}
        >
          <AuctionLoadingCard />
        </VehicleCardListItem>
      ))}
    </VehicleCardListContainer>
  );
};

export default VehicleLoadingCards;
