import { useTranslation } from '~/Locale';
import React from 'react';
import styled from 'styled-components';
import VehicleLoadingCards from '~/App/shared/components/VehicleLoadingCards';
import Auction from '~/App/shared/interfaces/Auction';
import VehicleCardList from '~/App/shared/components/VehicleCardList';
import { BodyText } from '@kvdbil/components';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';

const Notification = styled(BodyText)`
  text-align: center;
  padding: 2rem 3rem;
`;

export interface Props {
  isFetched: boolean;
  isError: boolean;
  numOfLoadingCards: number;
  auctions: Auction[];
  productCardVariant?: ProductCardVariant;
}

const RequestNotification = ({
  isError,
  isFetched,
  numOfLoadingCards,
  auctions,
  productCardVariant = ProductCardVariant.CarGuide
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {isError ? (
        <Notification data-testid="something-went-wrong-notification">
          {t(
            'Oops, it seems to be a problem with the server. We are looking in to it as soon as we can'
          )}
        </Notification>
      ) : (
        [
          isFetched ? (
            [
              auctions.length ? (
                <VehicleCardList
                  auctions={auctions}
                  productCardVariant={productCardVariant}
                />
              ) : (
                <Notification data-testid="no-results-notification">
                  {t('Your search yielded no results')}
                </Notification>
              )
            ]
          ) : (
            <VehicleLoadingCards numberOfCards={numOfLoadingCards} />
          )
        ]
      )}
    </>
  );
};

export default RequestNotification;
