import React, { useMemo } from 'react';
import {
  FormData,
  FormField,
  PREDEFINED_NEWSLETTER_CHECKBOXES,
  PREDEFINED_TOS_CHECKBOX
} from './DynamicForm';
import { Control } from 'react-hook-form';
import { BodyText, Flex } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import CheckBoxWithControl from '~/App/shared/components/Fields/CheckBoxWithControl';
import { getNewsletterCheckboxLabel } from './helpers';
import { TermsCheckbox } from '~/App/shared/components/Auth/Registration/components/TermsCheckbox';

type Props = {
  fields: FormField[];
  control: Control<FormData>;
};

const PredefinedFormFieldDispatcher = ({ fields, control }: Props) => {
  const { t } = useTranslation();
  const { newsletterCheckboxes, termsOfServiceCheckbox } = useMemo(() => {
    return {
      newsletterCheckboxes: fields.filter(({ fieldType }) =>
        PREDEFINED_NEWSLETTER_CHECKBOXES.includes(fieldType)
      ),
      termsOfServiceCheckbox: fields.find(
        ({ fieldType }) => fieldType === PREDEFINED_TOS_CHECKBOX
      )
    };
  }, [fields]) as {
    newsletterCheckboxes: FormField[];
    termsOfServiceCheckbox: FormField;
  };

  return (
    <>
      {termsOfServiceCheckbox && (
        <TermsCheckbox
          control={control}
          name={termsOfServiceCheckbox?.backendKey}
          required={termsOfServiceCheckbox?.required}
        />
      )}
      {newsletterCheckboxes.length > 0 && (
        <Flex direction="column" gap={0.5}>
          <BodyText>{t('I want to receive newsletters about...')}</BodyText>
          {newsletterCheckboxes.map(field => {
            const { fieldType, backendKey, required } = field;
            return (
              <CheckBoxWithControl
                key={backendKey}
                control={control}
                name={backendKey}
                label={getNewsletterCheckboxLabel(fieldType, t)}
                color="info"
                rules={{ required }}
              />
            );
          })}
        </Flex>
      )}
    </>
  );
};

export default PredefinedFormFieldDispatcher;
