import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import { requestErrorHandler } from '~/helpers/notifyError';
import VehicleCardListBlock from '~/App/shared/components/VehicleCardListBlock';
import Section from '~/App/shared/components/Layout/Section';
import Auction from '~/App/shared/interfaces/Auction';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import { fetchFeaturedObjects } from '~/App/views/Start/helpers';
import { CmsData } from '../interfaces/store/CmsData';
import { FeaturedObjectRecord } from '~/config/generated/graphql';
import { useInView } from 'react-intersection-observer';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Grid = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;

  ${mq(null, 'mobileL')} {
    gap: 3rem;
  }
`;

export type FeaturedObjectList = Pick<
  FeaturedObjectRecord,
  'heading' | 'linkText' | 'queryString'
> & {
  vehicleList?: Auction[] | [];
  hitsCounter?: { hits: number; total: number };
};

export interface FeaturedObjectsProps {
  lists: CmsData['startPage']['featuredObjects'];
  ssrData?: FeaturedObjectList[] | null;
}

const FeaturedObjects = ({ lists, ssrData }: FeaturedObjectsProps) => {
  const [isLoading, setIsLoading] = useState(!ssrData?.length);
  const [isError, setIsError] = useState(false);
  const [featuredObjects, setFeaturedObjects] = useState<FeaturedObjectList[]>(
    ssrData ?? []
  );
  const { ref, inView } = useInView({
    rootMargin: '100px',
    triggerOnce: true
  });

  const getFeaturedObjects = useCallback(async (data: FeaturedObjectList[]) => {
    try {
      const featuredObjects = await fetchFeaturedObjects(data);
      setFeaturedObjects(featuredObjects);
    } catch (error: unknown) {
      requestErrorHandler(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!ssrData && lists?.length && inView) {
      // TODO: store the result of this function in store to prevent it from being called on every page load
      void getFeaturedObjects(lists);
    }
  }, [lists, ssrData, inView, getFeaturedObjects]);

  if (isError) {
    return null;
  }

  return (
    <Section backgroundColor="light" spacing={3} padding={false}>
      <Container data-testid="featuredObjects" ref={ref}>
        <Grid>
          {lists?.map((featuredObject, index) => {
            const headerProps = {
              title: featuredObject.heading ?? '',
              hitsCounter: featuredObjects?.[index]?.hitsCounter ?? null,
              link: {
                label: featuredObject.linkText ?? '',
                url: featuredObject.queryString ?? ''
              }
            };
            return (
              <li key={featuredObject.queryString}>
                <VehicleCardListBlock
                  headerProps={headerProps}
                  auctions={
                    isLoading
                      ? Array(4).fill({ isLoader: true })
                      : featuredObjects?.[index]?.vehicleList ?? []
                  }
                  productCardVariant={ProductCardVariant.Regular}
                />
              </li>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

export default FeaturedObjects;
