import { BodyLink, BodyText, Chip } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import Link from '~/App/shared/components/Link';
import { ArticleRecord, ResponsiveImage } from '~/config/generated/graphql';
import { blogDateFormat } from '../helpers';
import { useTranslation } from '~/Locale';
import { Image } from 'react-datocms/image';
import { DeepPartial } from '@test/mocks';

const StyledLink = styled(Link)`
  position: relative;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  padding-bottom: 100%;
  background: ${({ theme }) => theme.colors.secondary.main};
`;

const StyledChip = styled(Chip)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0.75rem;
`;

const ArticleText = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
`;

const ArticleTitle = styled(BodyLink)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  margin-top: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface Props {
  article: DeepPartial<ArticleRecord>;
}

const ArticleCard = ({ article }: Props) => {
  const { title, mainCategory, _firstPublishedAt, slug, miniatureImage } =
    article;
  const { t } = useTranslation();

  return (
    <div data-testid="blog-article">
      <StyledLink to={`/ROUTES.BLOG/${mainCategory?.slug}/${slug}`}>
        {miniatureImage?.responsiveImage && (
          <Image data={miniatureImage?.responsiveImage as ResponsiveImage} />
        )}
        {!miniatureImage?.responsiveImage && <ImagePlaceholder />}
        <StyledChip color="neutralLight" size="small">
          {mainCategory?.name}
        </StyledChip>
      </StyledLink>
      <ArticleText>
        <ArticleTitle>{title}</ArticleTitle>
        <BodyText>
          {blogDateFormat(_firstPublishedAt as unknown as string, t)}
        </BodyText>
      </ArticleText>
    </div>
  );
};

export default ArticleCard;
