import { CmsDataStoreKey } from '~/App/shared/interfaces/store/CmsData';
import {
  handleFixedPriceReservationTipsData,
  handleObjectPageData,
  handleObjectServicesData
} from '~/App/views/ObjectPage/cmsDataHandlers';
import {
  handlePromoSettingCMSData,
  handleFilterPageData
} from '~/App/views/FilterPage/cmsDataHandlers';
import {
  handleCarTreeFamilyCmsData,
  handleCarTreeAllBrandsCmsData,
  handleCarAllBrandAndFamilySlugsData,
  handleCarBrandAndCategoryData
} from '~/App/views/CarTreeAndCategory/cmsDataHandlers';
import { handleNavigationCmsData } from '../../components/Header/components/DrawerMenu/cmsDataHandlers';
import { handleFooterNavigation } from '~/App/cmsDataHandlers';
import { handleStartPage } from '~/App/views/Start/cmsDataHandlers';
import { handleEmailSubscriptions } from '~/App/views/Unsubscribe/cmsDataHandlers';
import { handleServicesPage } from '~/App/views/Services/cmsDataHandlers';
import { handleCheckoutCmsData } from '~/App/views/Checkout/cmsDataHandlers';
import { handleCarguideCmsData } from '~/App/views/Carguide/cmsDataHandler';
import { handleTransferInfo } from '~/App/views/Transfer/cmsDataHandlers';
import { handleFaqPageCmsData } from '../components/FAQ/cmsDataHandler';
import { handleDynamicPage } from '~/App/views/DynamicPage/cmsDataHandlers';
import {
  handleGlobalConfiguration,
  handleSiteMessages
} from '../handlers/cmsDataHandler';
import {
  handleSellFlowConfirmationView,
  handleSellFlowHeavyEquipmentInterestForm,
  handleSellFlowInterestForm,
  handleSellFlowReservationView,
  handleSellFlowStartPage,
  handleSellFlowTermsAndConditionsView
} from '~/App/views/SellCar/cmsDataHandlers';
import {
  handleBlogAllCategories,
  handleBlogArticles,
  handleBlogArticlesByCategoryAndSlug,
  handleBlogCategoryBySlug,
  handleBlogStartPage
} from '~/App/views/Blog/cmsDataHandlers';
import { handlePartnershipPage } from '~/App/views/PartnershipPage/cmsDataHandlers';
import { handleBiddingView } from '~/App/components/BiddingView/components/Bidding/cmsDataHandlers';
import { handleFacilityPage } from '~/App/views/Facilities/cmsDataHandlers';

// Add CMS data handlers here
export const cmsDataHandlers = {
  carguide: handleCarguideCmsData,
  faqPage: handleFaqPageCmsData,
  checkout: handleCheckoutCmsData,
  navigation: handleNavigationCmsData,
  carBrandAndCategory: handleCarBrandAndCategoryData,
  carTreeFamily: handleCarTreeFamilyCmsData,
  carTreeAllBrands: handleCarTreeAllBrandsCmsData,
  carAllBrandAndFamilySlugs: handleCarAllBrandAndFamilySlugsData,
  objectPage: handleObjectPageData,
  objectServices: handleObjectServicesData,
  filterPage: handleFilterPageData,
  promoSettings: handlePromoSettingCMSData,
  fixedPriceReservationTips: handleFixedPriceReservationTipsData,
  footerNavigation: handleFooterNavigation,
  startPage: handleStartPage,
  emailSubscriptions: handleEmailSubscriptions,
  servicesPage: handleServicesPage,
  transferInfo: handleTransferInfo,
  dynamicPage: handleDynamicPage,
  globalConfiguration: handleGlobalConfiguration,
  siteMessages: handleSiteMessages,
  sellFlowStartPage: handleSellFlowStartPage,
  sellFlowInterestForm: handleSellFlowInterestForm,
  sellFlowHeavyEquipmentInterestForm: handleSellFlowHeavyEquipmentInterestForm,
  sellFlowConfirmationView: handleSellFlowConfirmationView,
  sellFlowReservationView: handleSellFlowReservationView,
  sellFlowTermsAndConditionsView: handleSellFlowTermsAndConditionsView,
  blogStartPage: handleBlogStartPage,
  blogAllCategories: handleBlogAllCategories,
  blogCategoryBySlug: handleBlogCategoryBySlug,
  blogArticlesForCategory: handleBlogArticles,
  blogArticlesByCategoryAndSlug: handleBlogArticlesByCategoryAndSlug,
  blogArticlesByCategories: handleBlogArticles,
  blogSearchAllArticles: handleBlogArticles,
  blogArticlesBySlugs: handleBlogArticles,
  partnershipPage: handlePartnershipPage,
  biddingView: handleBiddingView,
  facilityPage: handleFacilityPage
};

export type CmsDataHandlerValue<T extends CmsDataStoreKey> = ReturnType<
  (typeof cmsDataHandlers)[T]
>;

export const getCmsDataHandler = (storeKey: CmsDataStoreKey) => {
  return cmsDataHandlers[storeKey];
};

// This gets every argument for every handler and creates a union type, e.g Type1 | Type2 | Type3
type CmsHandlerPayloadUnion = Parameters<
  (typeof cmsDataHandlers)[CmsDataStoreKey]
>[0];

// This converts the union type to an intersection type, e.g Type1 & Type2 & Type3
type UnionToIntersection<U> = (
  U extends CmsHandlerPayloadUnion ? (x: U) => void : never
) extends (x: infer I) => void
  ? I
  : never;

// This is the final type that we use for the payload of the action
type CmsHandlerPayloadIntersection =
  UnionToIntersection<CmsHandlerPayloadUnion>;

export const setCmsData = (
  storeKey: CmsDataStoreKey,
  payload: CmsHandlerPayloadIntersection,
  storeNestedKey?: string
) => ({
  type: 'SET_CMS_DATA' as const,
  key: storeKey,
  nestedKey: storeNestedKey,
  payload: getCmsDataHandler(storeKey)(payload)
});

export const removeCmsData = (
  storeKey: CmsDataStoreKey,
  storeNestedKey?: string
) => ({
  type: 'REMOVE_CMS_DATA' as const,
  key: storeKey,
  nestedKey: storeNestedKey
});

export type SetCmsDataAction = ReturnType<typeof setCmsData>;
export type RemoveCmsDataAction = ReturnType<typeof removeCmsData>;
