import React from 'react';
import Header, {
  HeaderProps
} from '~/App/shared/components/VehicleCardListBlock/components/Header';
import styled from 'styled-components';
import Auction from '~/App/shared/interfaces/Auction';
import { Spacer, mq } from '@kvdbil/components';
import RequestNotification from '~/App/shared/components/VehicleCardListBlock/components/RequestNotification';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import AuctionsSlideShow from '~/App/shared/components/AuctionsSlideShow';

const Divider = styled.div`
  width: 98.5%;
  margin: auto;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.light5};

  ${mq(null, 'mobileL')} {
    width: 86%;
  }
`;

const VehicleCardListWrapper = styled.div`
  padding-top: 0.5rem;
`;

type Props = {
  headerProps: Omit<HeaderProps, 'color'>;
  productCardVariant: ProductCardVariant;
  auctions: Auction[];
  requestNotification?: {
    isFetched: boolean;
    isError: boolean;
    numOfLoadingCards: number;
  };
};

const VehicleCardListBlock = ({
  headerProps,
  productCardVariant,
  auctions,
  requestNotification
}: Props) => {
  return (
    <>
      <Header {...headerProps} color="primary" />
      <Divider />
      <Spacer />
      <VehicleCardListWrapper>
        {!requestNotification ? (
          <AuctionsSlideShow
            auctions={auctions}
            productCardVariant={productCardVariant}
          />
        ) : (
          <RequestNotification
            {...requestNotification}
            auctions={auctions}
            productCardVariant={productCardVariant}
          />
        )}
      </VehicleCardListWrapper>
    </>
  );
};

export default VehicleCardListBlock;
