import {
  fieldPropsEmail,
  fieldPropsNationalIdentificationNumber,
  fieldPropsPhoneNumber
} from '~/App/shared/components/Fields/shared/fieldProps';
import { FormField } from './DynamicForm';
import { TranslateFunction } from '~/Locale';

export const getFiledPropsFetcher = (
  textFieldType: FormField['textFieldType']
) => {
  switch (textFieldType) {
    case 'email':
      return fieldPropsEmail;
    case 'nationalIdentificationNumber':
      return fieldPropsNationalIdentificationNumber;
    case 'phoneNumber':
      return fieldPropsPhoneNumber;
    default:
      return null;
  }
};

export const getNewsletterCheckboxLabel = (
  fieldType: FormField['fieldType'],
  t: TranslateFunction
) => {
  switch (fieldType) {
    case 'predefined-carNewsletterCheckbox':
      return t('Personal car');
    case 'predefined-heavyVehiclesNewsletterCheckbox':
      return t('Heavy vehicles & machines');
    case 'predefined-corporateNewsletterCheckbox':
      return t('Fleet managers');
    default:
      return '';
  }
};
