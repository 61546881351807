import { FeaturedObjectList } from '~/App/shared/components/FeaturedObjects';
import { StartPageData } from '~/App/shared/interfaces/store/CmsData';
import { nonNullable } from '~/App/shared/types/helpers';
import { searchAuctions } from '~/helpers/orchestration/auctions';
import { getUrlSearchParams } from '~/helpers/url';

export const fetchFeaturedObjects = async (
  featuredObjects: StartPageData['featuredObjects']
): Promise<FeaturedObjectList[]> => {
  if (!featuredObjects?.length) {
    return [];
  }

  const featuredObjectsList = featuredObjects.filter(list => list.queryString);

  const listOfObjects = featuredObjectsList
    .map(({ queryString }) => queryString)
    .map(getUrlSearchParams)
    .map(queryParams => {
      return searchAuctions({
        idToken: '',
        cancellable: false,
        params: queryParams,
        offset: 0,
        limit: 4
      });
    });

  const results = await Promise.all(listOfObjects);

  return results
    .filter(nonNullable)
    .map(({ auctions, hits, total }, index) => ({
      ...featuredObjectsList?.[index],
      vehicleList: auctions,
      hitsCounter: { hits, total }
    }))
    .filter(({ vehicleList }) => Boolean(vehicleList?.length));
};
