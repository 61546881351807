import React from 'react';
import Auction from '~/App/shared/interfaces/Auction';
import VehicleCardListContainer from '~/App/shared/components/VehicleCardList/components/VehicleCardListContainer';
import VehicleCardListItem from '~/App/shared/components/VehicleCardList/components/VehicleCardListItem';
import ProductCard from '~/App/shared/components/ProductCard/ProductCard';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';

export interface VehicleCardListProps {
  productCardVariant: ProductCardVariant;
  auctions: Auction[];
}

const VehicleCardList = ({
  auctions = [],
  productCardVariant
}: VehicleCardListProps) => {
  return (
    <VehicleCardListContainer>
      {auctions.map((auction, index: number) => (
        <VehicleCardListItem key={`${index}`}>
          <ProductCard
            key={auction.id}
            auction={auction}
            variant={productCardVariant}
          />
        </VehicleCardListItem>
      ))}
    </VehicleCardListContainer>
  );
};

export default VehicleCardList;
